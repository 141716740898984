import { ReferencePickerType } from '@celito.clients/enums';
import { Filter } from '@celito.clients/types';
import { StringChainable } from 'ts-pattern/dist/types/Pattern';

import {
  IAllOrAnyFilter,
  ISimplifedFilter,
  RulesComponentListViewFilter,
} from '../../rules-component/types/rules-component.types';
import { IOption } from '../in-house-input-select.model';

export const convertCustomFilter = (
  filters?: RulesComponentListViewFilter | undefined,
  defaultReferenceFilter?: Filter[] | RulesComponentListViewFilter[],
  customSearch?: RulesComponentListViewFilter
): RulesComponentListViewFilter[] => {
  // Create a new object to avoid modifying the original filters
  const newFilters: RulesComponentListViewFilter = filters
    ? { ...filters }
    : {};

  if (defaultReferenceFilter && defaultReferenceFilter.length > 0) {
    // Create a new array and push defaultReferenceFilter into it
    const tempDefaultReferenceFilter =
      defaultReferenceFilter as RulesComponentListViewFilter[];

    if (tempDefaultReferenceFilter?.[0]?.conditions)
      newFilters.conditions = tempDefaultReferenceFilter?.[0]?.conditions;
    else
      newFilters.conditions = {
        all: [
          ...(newFilters.conditions?.all || []),
          {
            all: defaultReferenceFilter as ISimplifedFilter[],
          },
        ],
      };
  }

  if (customSearch) {
    // Iterate through customSearch and push its conditions into newFilters
    customSearch.conditions?.all.forEach((cond) => {
      const existingConditions = newFilters.conditions?.all || [];
      const newCondition: IAllOrAnyFilter[] = cond.all
        ? [{ all: cond.all }]
        : [{ any: cond.any }];

      newFilters.conditions = {
        all: [...existingConditions, ...newCondition],
      };
    });
  }
  // Return an array containing newFilters if it has conditions, otherwise return an empty array
  return newFilters.conditions ? [newFilters] : [];
};

export const removeDuplicates = (options: IOption[]): IOption[] => {
  const duplicateTracker = new Set<string>();
  const uniqueOptions: IOption[] = [];

  options.forEach((option) => {
    // Create a unique key based on value and text
    const key = `${option.value}|${option.text}`;

    if (!duplicateTracker.has(key)) {
      uniqueOptions.push(option);
      duplicateTracker.add(key);
    }
  });

  return uniqueOptions;
};

export const getText = (
  label?: string,
  title?: string,
  pickerType?: ReferencePickerType
) => {
  if (pickerType === ReferencePickerType.LABEL_TITLE && title && label) {
    return `${label} (${title})`;
  } else if (
    pickerType === ReferencePickerType.TITLE ||
    pickerType === ReferencePickerType.TITLE__S
  ) {
    return title ?? label;
  } else if (pickerType === ReferencePickerType.LABEL_OR_TITLE) {
    return `${label} (${title})`;
  } else {
    return label ?? title;
  }
};

export const getRecordNameFromUrl = (path: string) => {
  // Extract pathname without query parameters

  // Get all segments and take the last one
  const pathSegments = path.split('/').filter((segment) => segment.length > 0);
  return pathSegments[pathSegments.length - 1]; // Last segment before `?`
};
