import { ApiMethodType } from '@celito.clients/enums';
import { performFormRequest, performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

export const saveDetailsAPI = async (
  recordName: string,
  payload: object,
  objectName = 'controlled_document__a'
) => {
  const response = await performFormRequest(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/${recordName}`,
    ApiMethodType.PUT,
    payload
  );
  return response.data as Record<string, unknown>;
};

export const checkInDetailsAPI = async (
  recordName: string,
  payload: object
) => {
  const response = await performFormRequest(
    `controlled-document/${recordName}/check-in`,
    ApiMethodType.PUT,
    payload
  );
  return response.data as Record<string, unknown>;
};

export const getControlledDocumentData = async (
  name: string,
  version: string
) => {
  const response = await performRequest(
    `${
      Endpoints.OBJECT_DATA_URL
    }/${'controlled_document__a'}/${name}?view_type=edit&version=${version}`,
    ApiMethodType.GET
  );
  return response.data;
};
