import { LocalizationString } from '@celito.clients/assets';
import { ActionTypeEnum } from '@celito.clients/enums';
import {
  useActiveModule,
  useQueryParams,
  useURLParams,
} from '@celito.clients/hooks';
import {
  ObjectAttributeType,
  WorkflowControlsDefinition,
  WorkflowControlVariableDataTypeEnum,
} from '@celito.clients/types';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActionLevelEnum } from 'libs/core/src/enums/action-type';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { DefaultValues, FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { getFormValues, getRequiredFormatData } from '../../../utils/helper';
import WorkflowActionContext from '../provider/action-provider';
import {
  executeUpdateWorkflow,
  executeWorkflow,
  updateAddParticipantField,
  updateTaskActionFields,
} from '../workflow-actions/services';
import {
  ActionsHandlerComponentProps,
  Controls,
  RequestPayload,
  WorkflowControlTypeEnum,
} from './actions-handler.model';
import { ActionHandleView } from './actions-handler.view';
import {
  getControlDataKey,
  getRolesLabel,
  modifiedPayload,
  transformBasedOnControlType,
} from './utils';

export const ActionHandlerController = (
  props: ActionsHandlerComponentProps
): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<yup.AnyObject> | undefined
  >(yup.object().shape({}));
  const [allControls, setAllControls] = useState<Controls[]>([]);
  const [instruction, setInstruction] = useState<string>('');
  const methods = useForm({
    resolver: yupResolver(validationSchema || yup.object().shape({})),
  });
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [attributeConfig] = useState<ObjectAttributeType>(
    cloneDeep(props?.attributeConfig)
  );
  const [formData, setFormData] = useState<Record<string, string>[]>([]);
  const { handleSubmit, reset, getValues } = methods;
  const { onActionClick, onTaskActionClick } = useContext(
    WorkflowActionContext
  );
  const navigate = useNavigate();
  const activeModule = useActiveModule();
  const { urlParams } = useURLParams();
  const { getSearchParams } = useQueryParams();
  const searchParams = getSearchParams();
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (
      props?.attributeConfig &&
      props?.recordData &&
      (props?.selectedAction?.actionType === ActionTypeEnum.UPDATE_WORKFLOW ||
        props?.selectedAction?.actionType === ActionTypeEnum.TRIGGER_WORKFLOW)
    ) {
      reset({
        ...getFormValues(props?.attributeConfig, props?.recordData),
        ...props?.prePopulatedData,
      });
    }
    getAllControlsOfActions();
  }, [
    props?.controls,
    props?.recordData,
    props?.attributeConfig,
    props?.prePopulatedData,
  ]);

  useEffect(() => {
    if (
      props?.attributeConfig &&
      props?.recordData &&
      props?.recordData?.isGxp
    ) {
      const currentValues = getValues();

      if (props?.currentTaskTag?.includes('functional_approval')) {
        reset({
          ...currentValues,
          is_gxp__a: true,
          quality_approvers__a: props?.recordData?.qualityApprovers ?? [],
        });
      }
      if (props?.currentTaskTag?.includes('quality_approval')) {
        reset({
          ...currentValues,
          is_gxp__a: true,
          required_approvers__a: props?.recordData?.requiredApprovers ?? [],
        });
      }
    }
  }, [props?.recordData, props?.attributeConfig]);

  const onSave = async () => {
    await handleSubmit((data: Record<string, any>) => {
      const formData = Object.keys(data).map((key) => {
        const label = getControlDataKey(key, props?.controls);
        return {
          label: getControlDataKey(key, props?.controls),
          value:
            label && getRequiredFormatData(data[key], key, attributeConfig),
        };
      });
      onCompleteClicked(formData);
    })();
  };

  const onCompleteClicked = (formData: Record<string, string>[]) => {
    if (props?.selectedAction?.actionType === ActionTypeEnum.TRIGGER_WORKFLOW) {
      executeWorkflowInformation(formData);
    } else {
      props?.closeActionPopup();
      setOpenConfirmationDialog(true);
      setFormData(formData);
    }
  };
  const executeWorkflowInformation = async (
    formData: Record<string, string>[]
  ) => {
    setIsLoading(true);
    let addParticipantRes = null;
    let reassignRes = null;
    const obj: RequestPayload = {};
    formData?.forEach((data) => {
      if (data.label && data.value) {
        obj[data.label] = data.value;
      }
    });
    try {
      if (
        props?.selectedAction?.actionType === ActionTypeEnum.UPDATE_WORKFLOW
      ) {
        const payload: object = {
          controlData: obj,
        };
        await executeUpdateWorkflow(
          props?.recordData?.name as string,
          props?.recordData?.version as string,
          payload
        );
      } else if (
        props?.selectedAction?.actionLevel === ActionLevelEnum.TaskGroup
      ) {
        const payload: object = {
          participantControlData: obj,
        };
        addParticipantRes = await updateAddParticipantField(
          props.taskName!,
          payload
        );
      } else if (props?.selectedAction?.actionLevel === ActionLevelEnum.Task) {
        const payload: object = modifiedPayload(
          props?.selectedAction,
          formData
        );
        reassignRes = await updateTaskActionFields(props.taskName!, payload);
      } else {
        const payload: object = {
          controlData: obj,
        };
        await executeWorkflow(
          props.selectedAction.properties.workflowName!,
          props?.recordData?.name as string,
          props?.recordData?.version as string,
          payload
        );
      }
      setIsLoading(false);
      setOpenConfirmationDialog(false);
      successToast({
        message: `${props?.selectedAction?.label} executed successfully`,
      });
      reset();
      props?.closeActionPopup();
      props?.setActionsToLoad();
      if (
        addParticipantRes?.[0]?.objectRecordVersion ??
        reassignRes?.objectRecordVersion
      ) {
        updateUrl(
          addParticipantRes?.[0]?.objectRecordVersion ??
            reassignRes?.objectRecordVersion ??
            ''
        );
      }
      onActionClick(
        addParticipantRes?.[0]?.objectRecordVersion ??
          reassignRes?.objectRecordVersion ??
          searchParams?.version ??
          ''
      );
      if (
        props?.selectedAction?.actionType !== ActionTypeEnum.UPDATE_WORKFLOW &&
        props?.selectedAction?.actionType !== ActionTypeEnum.TRIGGER_WORKFLOW
      ) {
        onTaskActionClick(
          addParticipantRes?.[0]?.objectRecordVersion ??
            reassignRes?.objectRecordVersion ??
            searchParams?.version ??
            ''
        );
      }
      if (
        props?.selectedAction?.actionType === ActionTypeEnum.TRIGGER_WORKFLOW
      ) {
        if (activeModule?.link) {
          navigate(activeModule?.link);
        }
      }
    } catch (error) {
      raiseErrorToast(error);
      setIsLoading(false);
      reset();
      props?.closeActionPopup();
      setOpenConfirmationDialog(false);
    }
  };

  const onConfirmationClick = () => {
    executeWorkflowInformation(formData);
  };

  const transformDataAsPerLayoutRule = (
    controlsDefinition: WorkflowControlsDefinition[]
  ) => {
    const modifiedControls: Controls[] = [];
    controlsDefinition?.forEach((controls, index) => {
      if (controls?.controlType === WorkflowControlTypeEnum.INSTRUCTION) {
        if ((controls?.permission || controls?.defaultRule) !== 'HIDDEN') {
          setInstruction(controls?.controlConfig?.instruction as string);
        } else {
          setInstruction('');
        }
      }
      if (
        controls?.controlConfig?.dataType ===
        WorkflowControlVariableDataTypeEnum.USER_OBJECT_ROLES
      ) {
        const patchDefaultValue =
          controls?.controlConfig?.dataType ===
          WorkflowControlVariableDataTypeEnum.USER_OBJECT_ROLES
            ? getRolesLabel((controls?.controlConfig?.roles as string[]) || [])
            : '';
        const currentValues = getValues();
        reset({
          ...currentValues,
          [controls?.name]: patchDefaultValue,
        });
      }
      const transformedControls = transformBasedOnControlType(
        controls,
        index,
        attributeConfig
      );
      modifiedControls.push(...transformedControls);
    });
    return modifiedControls;
  };

  const getAllControlsOfActions = async () => {
    if (props?.controls?.length) {
      const controlstoRender = transformDataAsPerLayoutRule(props?.controls);
      if (props?.currentTaskDueDate) {
        const currentValues = getValues();
        reset({
          ...currentValues,
          due_date__a: props?.currentTaskDueDate,
        });
      }
      setAllControls(controlstoRender);
    }
  };
  const goBack = () => {
    setOpenConfirmationDialog(false);
    props?.openActionPopup();
  };

  const closePopup = () => {
    props?.closeActionPopup();
    if (
      props?.selectedAction?.actionType !== ActionTypeEnum.UPDATE_WORKFLOW &&
      props?.selectedAction?.actionType !== ActionTypeEnum.TRIGGER_WORKFLOW
    )
      methods.reset(defaultFormValues());
  };

  const getButtonTitle = () => {
    switch (props?.selectedAction?.actionType) {
      case ActionTypeEnum.UPDATE_WORKFLOW:
      case ActionTypeEnum.CHNAGE_DUE_DATE_TASK:
        return LocalizationString.UPDATE;
      case ActionTypeEnum.REASSIGN_TASK:
        return LocalizationString.REASSIGN;
      case ActionTypeEnum.ADD_PARTICIPANTS:
        return LocalizationString.ADD;
      default:
        return LocalizationString.COMPLETE;
    }
  };

  const getActionTitlesAndDesc = () => {
    switch (props?.selectedAction?.actionType) {
      case ActionTypeEnum.UPDATE_WORKFLOW:
        return {
          title: LocalizationString.UPDATE_WORKFLOW_CONFIRMATION_TEXT,
          confirmationText: LocalizationString.UPDATE__CONFIRMATION_DESCRIPTION,
        };
      case ActionTypeEnum.CHNAGE_DUE_DATE_TASK:
        return {
          title: LocalizationString.CHANGE_WORKFLOW_DUE_DATE,
          confirmationText: LocalizationString.CHNAGE_DUE_DATE_CONFIRMATION,
        };
      case ActionTypeEnum.REASSIGN_TASK:
        return {
          title: LocalizationString.REASSIGN_USER,
          confirmationText: LocalizationString.REASSIGN_USER_CONFIRMATION,
        };
      case ActionTypeEnum.ADD_PARTICIPANTS:
        return {
          title: LocalizationString.ADD_PARTICIPANTS,
          confirmationText: LocalizationString.ADD_PARTCIPANTS_CONFIRMATION,
        };
      default:
        return {
          title: '',
          confirmationText: '',
        };
    }
  };

  const defaultFormValues = () => {
    const defaultValues: DefaultValues<FieldValues> = {};

    if (!attributeConfig) {
      return defaultValues;
    }
    attributeConfig.objectAttributeDefinitions.forEach((attribute) => {
      if (attribute?.name) {
        defaultValues[attribute.name] = {};
      }
    });

    return defaultValues;
  };
  const updateUrl = (objectRecordVersion: string) => {
    urlParams.set('version', objectRecordVersion);

    const newParam = `?${urlParams.toString()}`;
    setParams(newParam, { replace: true });
  };
  return (
    <ActionHandleView
      {...props}
      open={props?.openHandler}
      closePopup={closePopup}
      onSave={onSave}
      methods={methods}
      setValidationSchema={setValidationSchema}
      isLoading={isLoading}
      allControls={allControls}
      instruction={instruction}
      openConfirmationDialog={openConfirmationDialog}
      goBack={goBack}
      updatedAttributeConfig={attributeConfig}
      onConfirmationClick={onConfirmationClick}
      getButtonTitle={getButtonTitle}
      getActionTitlesAndDesc={getActionTitlesAndDesc}
      isDisabledButtton={
        props?.selectedAction?.actionType === ActionTypeEnum.REASSIGN_TASK ||
        props?.selectedAction?.actionType === ActionTypeEnum.ADD_PARTICIPANTS
          ? methods?.formState.isDirty
          : true
      }
    />
  );
};
