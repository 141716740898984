import { LocalizationString } from '@celito.clients/assets';
import { TrainingPlanStatusEnum } from '@celito.clients/enums';
import {
  useCallbackPrompt,
  useDeepCompareEffect,
  useLayout,
  useURLParams,
} from '@celito.clients/hooks';
import { editFormSubmitApi, formSubmitApi } from '@celito.clients/services';
import {
  ControlledDocumentRequestTypeEnum,
  Field,
  TrainingPlanRequestTypeEnum,
} from '@celito.clients/types';
import { errorToast, formatDateInTimezone } from '@celito.clients/utils';
import { FileDataProps } from 'libs/shared/src/lib/multi-file-upload/multi-file-upload.model';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import {
  smartGroupPreviewPropName,
  trainessPreviewPropName,
  trainingRolesPreviewPropName,
} from '../../../custom-pages/assingment-page/config';
import { useFormEngineContext } from '../../../hooks';
import useFormApiErrorHandler from '../../../hooks/use-form-api-error-handler';
import { RecorLevelUserContextFields } from '../../../types/common-types';
import {
  atLeastOneValueExists,
  getFormValues,
  refactorFormData,
} from '../../../utils/helper';
import { WizardContainerComponentProps } from '../wizard-container.model';
import { AddScreenProps } from './add-screen.model';
import AddScreenView from './add-screen.view';

const attachmentsPropName = 'attachments__a';
// for system objects the attachment key name will be attachments__s
const attachmentSystemPropName = 'attachments__s';
const filePropName = 'file__a';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const AddScreenController = (props: WizardContainerComponentProps) => {
  const {
    sections = [],
    attributeConfig: objectDefinition,
    objectName,
    step,
    submitUrl = '',
    totalSteps = 0,
  } = props;
  const { configureLayout } = useLayout();
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [recordDetails, setRecordDetails] = useState<Record<
    string,
    unknown
  > | null>(null);
  const { urlParams } = useURLParams();
  const { handleFormApiError } = useFormApiErrorHandler();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    setFieldsApiErrors,
    onCancel: onCanceHandler,
    onSubmit,
  } = useFormEngineContext();

  const requestType = urlParams.get('requestType');
  const isTrainingPlanRequest =
    requestType === TrainingPlanRequestTypeEnum.NewTrainingPlan;
  const isControlledDocumentRequest =
    requestType === ControlledDocumentRequestTypeEnum.NewDocument;

  const navigate = useNavigate();

  const methods = useFormContext();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    formState: { isDirty, dirtyFields },
    reset,
    getValues,
  } = methods;

  const allSectionsFields = useMemo(() => {
    return sections.reduce((acc, section) => {
      return acc.concat(section.fields);
    }, [] as Field[]);
  }, [sections]);

  useEffect(() => {
    if (requestType) {
      setValue('initiation_type__a', requestType);
      configureLayout({
        pageTitle: '',
        headerTitle: requestType, // TODO: should come from viewDTO
        enablePadding: false,
      });
    }
  }, [requestType]);

  useDeepCompareEffect(() => {
    if (recordDetails) {
      const responseValues = getFormValues(objectDefinition, recordDetails);
      const currentFormState = getValues();

      Object.keys(currentFormState).forEach((fieldName) => {
        currentFormState[fieldName] =
          currentFormState[fieldName] !== null
            ? currentFormState[fieldName]
            : responseValues[fieldName];
      });

      reset({
        ...responseValues,
        ...currentFormState,
        file__a:
          responseValues.file__a ?? currentFormState.file__a ?? undefined,
        attachments__a:
          responseValues.attachments__a ??
          currentFormState.attachments__a ??
          undefined,
      });
    }
  }, [getValues, recordDetails]);

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    !!props.showCancelPopup && isDirty
  );

  const toggleWarningModalBtnClick = () => {
    setIsWarningModalOpen(!isWarningModalOpen);
  };

  const toggleSaveModalBtnClick = () => {
    setIsSaveModalOpen(!isSaveModalOpen);
    reset({ ...getValues(), isDirty: false });
    if (isSaveModalOpen) onSubmit?.();

    // NOTE: do not remove the commented lines
    // if (isSaveModalOpen) navigate(-1);
  };

  const removeSearchParam = (param: string) => {
    const updatedParams = new URLSearchParams(searchParams);
    const parentRecord = updatedParams.get(param);
    if (parentRecord) {
      updatedParams.delete(param);
      setSearchParams(updatedParams, { replace: true });
    }
  };

  const toggleSubmitModalBtnClick = () => {
    setIsSubmitModalOpen(!isSubmitModalOpen);
    reset({ ...getValues(), isDirty: false });

    if (isSubmitModalOpen) {
      if (onSubmit) {
        onSubmit();
      } else if (submitUrl) {
        navigate(`../${submitUrl}`);
      } else {
        removeSearchParam('pr');
        navigate(-1);
      }
    }
  };

  const toggleCancelModalBtnClick = (navigateBack?: boolean) => {
    setIsCancelModalOpen((prev) => !prev);
    if (navigateBack) {
      reset({ isDirty: false });
      if (onCanceHandler) {
        onCanceHandler();
        confirmNavigation();
        onRouteBack();
      }
    }
  };

  const onCancel: AddScreenProps['onCancel'] = (e) => {
    e.preventDefault();

    if (props.showCancelPopup && isDirty) {
      setIsCancelModalOpen(true);
    } else {
      onCanceHandler ? onCanceHandler() : onRouteBack();
    }
  };

  const onRouteBack = () => {
    navigate(-1);
  };

  const onFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    handleSubmit(() => onSave(false));
  };

  const callFormApi = async (
    payload: FormData,
    isSubmit: boolean,
    extraQueryString: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    isSubmit ? setIsDataSubmitted(true) : setIsDataSaved(true);
    try {
      const submitFlag = extraQueryString?.isSubmit;

      if (submitFlag) {
        delete extraQueryString.isSubmit;
      }
      const params = {
        ...(isSubmit && { isSubmit: true }),
        ...extraQueryString,
      };

      const response = await formSubmitApi(objectName, payload, params);

      if (response.record.name) {
        setRecordDetails({
          ...response.record,
        });
      }
      confirmNavigation();

      if (isSubmit) {
        toggleSubmitModalBtnClick();
      } else if (!fromNextOrPrevious) {
        toggleSaveModalBtnClick();
      }
      setFieldsApiErrors?.({
        fields: [],
        sections: [],
      });
    } catch (_error) {
      handleFormApiError(_error, sections);
    } finally {
      isSubmit ? setIsDataSubmitted(false) : setIsDataSaved(false);
    }
  };

  const callEditFormApi = async (
    payload: FormData,
    isSubmit: boolean,
    extraQueryString: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    isSubmit ? setIsDataSubmitted(true) : setIsDataSaved(true);
    try {
      const submitFlag = extraQueryString?.isSubmit;

      if (submitFlag) {
        delete extraQueryString.isSubmit;
      }
      const params: Record<string, unknown> = {
        ...(isSubmit && { isSubmit: true }),
        ...(props.labelFormat && { recordName: payload?.get('name__s') }),

        ...extraQueryString,
      };

      if (
        objectDefinition?.isVersioningEnabled &&
        !extraQueryString.skipVersionUpgrade
      ) {
        params.skipVersionUpgrade =
          (Object.keys(dirtyFields).length >= 1 &&
            recordDetails?.isActive &&
            !isSubmit) ||
          false;
      }

      if (params.skipVersionUpgrade && recordDetails?.version) {
        params.version = recordDetails.version;
      }

      if (props.labelFormat) {
        payload.delete('name__s');
        payload.delete('label__s');
      }

      const response = await editFormSubmitApi(
        objectName,
        recordDetails?.name as string,
        payload,
        params
      );

      setValue(`deleted_${attachmentsPropName}`, '');
      setValue(`deleted_${attachmentSystemPropName}`, '');
      setValue(`deleted_${filePropName}`, []);

      if (response.record.name && response.record.version) {
        setRecordDetails({
          ...response.record,
        });
      }

      confirmNavigation();

      if (isSubmit) {
        toggleSubmitModalBtnClick();
      } else if (!fromNextOrPrevious) {
        toggleSaveModalBtnClick();
      }
      setFieldsApiErrors?.({
        fields: [],
        sections: [],
      });
    } catch (_error) {
      handleFormApiError(_error, sections);
    } finally {
      isSubmit ? setIsDataSubmitted(false) : setIsDataSaved(false);
    }
  };

  const submitForm = async (
    rawData: FieldValues,
    isSubmit: boolean,
    extraQueryString: Record<string, unknown> = {},
    formDataObject: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    if (!rawData.completion_type__a) {
      delete rawData.completion_type__a;
    }
    let data = refactorFormData(objectDefinition, rawData);
    if (Object.keys(formDataObject).length > 0) {
      data = { ...data, ...formDataObject };
    }

    if (!data?.file__a?.documentId) {
      data.file__a = watch('file__a');
    }

    // for control docs
    const attachments =
      watch(attachmentsPropName) || watch(attachmentSystemPropName);
    if (attachments?.length > 0) {
      data.secondary_file__a = recordDetails?.name
        ? attachments.filter(
            // only File type should be passed to secondary_file__a and will act as append in the DB
            // in EDIT mode. When record is already created, the screen should be treated as EDIT mode
            (attachment: File | FileDataProps) => attachment instanceof File
          )
        : [...attachments];
    }

    let markedForDeleteAttachments: string[] = [];
    let documentDeleted = false;

    if (recordDetails?.name) {
      // if recordDetails exists, record has been created and form is treated as EDIT mode
      markedForDeleteAttachments =
        watch(`deleted_${attachmentsPropName}`) ??
        watch(`deleted_${attachmentSystemPropName}`) ??
        [];
      const markedForDeleteFile = watch(`deleted_${filePropName}`) ?? [];

      if (markedForDeleteFile?.length) {
        documentDeleted = true;
      }

      delete data?.version__s;
    }

    delete data?.[attachmentsPropName];
    delete data?.[`deleted_${attachmentsPropName}`];
    delete data?.[attachmentSystemPropName];
    delete data?.[`deleted_${attachmentSystemPropName}`];
    delete data?.[`deleted_${filePropName}`];
    delete data?.[trainessPreviewPropName];
    delete data?.[smartGroupPreviewPropName];
    delete data?.[trainingRolesPreviewPropName];
    delete data?.['isDirty'];
    delete data?.['name__s'];
    delete data?.['label__s'];
    delete data?.['is_trainable__a'];
    delete data?.['lifecycle_stage__s'];
    delete data?.['document_status__a'];
    delete data?.['currentQuestion'];
    delete data?.['isEditMode'];
    delete data?.['modified_by_user__s'];
    delete data?.['created_by_user__s'];
    delete data?.['created_at_utc__s'];
    delete data?.['modified_at_utc__s'];
    delete data?.['requestor__a'];
    delete data?.['uuid__s'];
    delete data?.[RecorLevelUserContextFields.RECORD_AND_SYSTEM_ROLES];

    if (objectDefinition?.name === 'quiz__a') {
      delete data?.['initiation_type__a'];
    }

    const formData = new FormData();

    if (documentDeleted) {
      // tells BE that the file has been deleted
      formData.append(`documentDeleted`, 'true');
    }

    if (markedForDeleteAttachments.length > 0) {
      formData.append(
        'deletedDocumentIds',
        JSON.stringify(markedForDeleteAttachments)
      );
    }

    Object.keys(data).forEach((key) => {
      if (
        data[key] !== undefined &&
        data[key] !== null &&
        !(data[key] instanceof Date) &&
        data[key] !== '' &&
        !Number.isNaN(data[key])
      ) {
        // for multi file uploads
        if (Array.isArray(data[key]) && key === 'secondary_file__a') {
          for (let i = 0; i < data[key].length; i++) {
            formData.append(key, data[key][i]);
          }
          return;
        }

        formData.append(
          key,
          Array.isArray(data[key])
            ? data[key].length > 0
              ? JSON.stringify(data[key])
              : null
            : data[key]
        );
      }
      if (data[key] instanceof Date) {
        formData.append(key, formatDateInTimezone(data[key]));
      }
    });

    // call the Edit API if the record is already created
    if (recordDetails?.name) {
      callEditFormApi(
        formData,
        isSubmit || (extraQueryString?.isSubmit as boolean),
        {
          ...extraQueryString,
        },
        fromNextOrPrevious
      );
      return;
    }

    callFormApi(
      formData,
      isSubmit || (extraQueryString?.isSubmit as boolean),
      extraQueryString,
      fromNextOrPrevious
    );
  };

  const onValidSubmitForm = (
    rawData: FieldValues,
    isSubmit: boolean,
    extraQueryString: Record<string, unknown> = {},
    formDataObject: Record<string, unknown> = {},
    fromNextOrPrevious: boolean
  ) => {
    const oneValueExists = atLeastOneValueExists(rawData, allSectionsFields);

    if (!fromNextOrPrevious && !oneValueExists) {
      errorToast({
        message: LocalizationString.VALIDATION_DESCRIPTION,
      });
      return;
    }

    if (oneValueExists) {
      submitForm(
        rawData,
        isSubmit,
        extraQueryString,
        formDataObject,
        fromNextOrPrevious
      );
    }
  };

  const onInvalidSubmitForm = (
    isSubmit: boolean,
    extraQueryString: Record<string, unknown> = {},
    formDataObject: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    const values = getValues();
    const oneValueExists = atLeastOneValueExists(values, allSectionsFields);

    if (isSubmit || (!fromNextOrPrevious && !oneValueExists)) {
      errorToast({
        message: LocalizationString.VALIDATION_DESCRIPTION,
      });
      return;
    }

    if (oneValueExists) {
      submitForm(
        values,
        isSubmit,
        extraQueryString,
        formDataObject,
        fromNextOrPrevious
      );
    }
  };

  const onSave = async (
    isSubmit = false,
    extraQueryString: Record<string, unknown> = {},
    formDataObject: Record<string, unknown> = {},
    fromNextOrPrevious = false
  ) => {
    if (!isSubmit) {
      const formValues = getValues();
      return onValidSubmitForm(
        formValues,
        isSubmit,
        extraQueryString,
        formDataObject,
        fromNextOrPrevious
      );
    }

    await handleSubmit(
      (rawData) =>
        onValidSubmitForm(
          rawData,
          isSubmit,
          extraQueryString,
          formDataObject,
          fromNextOrPrevious
        ),
      (_err) =>
        onInvalidSubmitForm(
          isSubmit,
          extraQueryString,
          formDataObject,
          fromNextOrPrevious
        )
    )();
  };

  const handleGoToNextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (sections?.[step]?.pageComponent) {
      props.goToNextStep(e);
      return;
    }

    if (step <= totalSteps - 1) {
      // validate the current step before advancing
      // for custom screens, validation is done at the custom screen level
      const currentStepFields = (sections?.[step]?.fields ?? []).map(
        (field) => field.columnName
      );

      // validate only current section fields
      const isValid = await trigger(currentStepFields);

      props.setWizardStepsState((prev) =>
        prev.map((prevStep) =>
          prevStep.number === step ? { ...prevStep, isValid } : prevStep
        )
      );

      if (!isValid) {
        return;
      }

      await onSave(
        false,
        undefined,
        {
          ...(isTrainingPlanRequest && {
            request_type__s: 'NewTrainingPlan',
            status__a: TrainingPlanStatusEnum.Draft,
          }),
          ...(isControlledDocumentRequest && {
            initiation_type__a: requestType,
          }),
        },
        true
      );

      props.goToNextStep(e);
    }
  };

  return (
    <AddScreenView
      {...props}
      {...{
        onCancel,
        attributeConfig: objectDefinition,
        control,
        onSave,
        handleSubmit,
        onFormSubmit,
        watch,
        setValue,
        trigger,
        step,
        sections,
        submitUrl,
        totalSteps,
        isSaveModalOpen,
        isWarningModalOpen,
        isSubmitModalOpen,
        toggleWarningModalBtnClick,
        toggleSaveModalBtnClick,
        toggleSubmitModalBtnClick,
        isCancelModalOpen,
        mode: props.mode,
        toggleCancelModalBtnClick,
        methods,
        isLoading: isDataSaved,
        isSubmitting: isDataSubmitted,
        cancelNavigation,
        confirmNavigation,
        showPrompt,
        goToNextStep: handleGoToNextStep,
      }}
    />
  );
};
