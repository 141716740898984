import { ActionTypeEnum } from '@celito.clients/enums';
import {
  ObjectActionDefinition,
  WorkflowControlsDefinition,
  WorkflowControlVariableDataTypeEnum,
} from '@celito.clients/types';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { PopoverProps } from '@fluentui/react-components';
import { useEffect, useMemo, useState } from 'react';

import WorkflowActionContext from '../provider/action-provider';
import {
  executeWorkflow,
  getPrePopulatedValue,
  getTaskControls,
  getUpdateWorkflowControls,
  getWorkflowControls,
} from './services';
import {
  getFilteredTaskActions,
  getSortedControls,
  getWorkflowActions,
} from './utils';
import {
  Actions,
  TaskActions,
  TaskAndUserActions,
  WorkflowActionsComponentProps,
} from './workflow-actions.model';
import WorkflowActionsView from './workflow-actions.view';
export const WorkflowActionsController = (
  props: WorkflowActionsComponentProps
) => {
  const { refetchRecordData, callWorkflowAPIOnEvent } = props;
  const [actions, setActions] = useState<ObjectActionDefinition[]>();
  const [workflowActions, setWorkflowActions] = useState<
    ObjectActionDefinition[]
  >([]);
  const [openHandler, setOpenHandler] = useState(false);
  const [selectedAction, setSelectedAction] =
    useState<ObjectActionDefinition>();
  const [controls, setControls] = useState<WorkflowControlsDefinition[]>([]);
  const [prePopulatedData, setPrePopulatedData] =
    useState<Record<string, unknown>>();
  const [isActionsLoading, setIsActionsLoading] = useState(false);
  const [updateDependency, setUpdateDependency] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenChange: PopoverProps['onOpenChange'] = (e, data) =>
    setOpen(data.open || false);

  useEffect(() => {
    const popup = props?.popupRef?.current;
    if (open) {
      popup?.addEventListener('scroll', handleScroll);
    } else {
      popup?.removeEventListener('scroll', handleScroll);
    }

    return () => {
      popup?.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  useEffect(() => {
    if (props?.actionType === ActionTypeEnum.WORKFLOW) {
      filterWorflowActions();
    }
  }, [props?.recordData, props?.objectActionDefinitions, updateDependency]);

  useEffect(() => {
    if (props?.actionType === ActionTypeEnum.WORKFLOW) {
      patchPrePopulatedValue();
    }
  }, [props?.recordData, props?.objectActionDefinitions]);

  const filterWorflowActions = () => {
    const actions = getWorkflowActions(
      props?.recordData?.recordWorkflowActions as Actions[],
      props.objectActionDefinitions!
    );
    if (actions?.length) {
      setWorkflowActions(actions);
      setIsActionsLoading(false);
    }
  };
  const handleScroll = () => {
    setOpen(false);
  };
  const getActionsBasedOnActionType = async (actionType: ActionTypeEnum) => {
    if (props?.taskActions) {
      const actions = getFilteredTaskActions(
        props?.taskActions,
        props.objectActionDefinitions!
      );
      filterActionsBasedOnActionType(actions, actionType);
    }
  };

  const setActionsToLoad = () => {
    setIsActionsLoading(true);
    setWorkflowActions([]);
    setUpdateDependency(true);
  };
  const filterActionsBasedOnActionType = (
    actions: TaskAndUserActions,
    actionType: ActionTypeEnum
  ) => {
    if (!actions) {
      setActions([]);
    } else if (actionType === ActionTypeEnum.STAGE) {
      setActions(actions?.task);
    } else {
      setActions(actions?.user);
    }
  };

  const callExecuteWorfklow = async (
    action: ObjectActionDefinition | TaskActions
  ) => {
    try {
      const executedData = await executeWorkflow(
        (action as ObjectActionDefinition).properties.workflowName as string,
        props?.recordData?.name as string,
        props?.recordData?.version as string,
        {}
      );

      if (executedData.status >= 200 && executedData.status < 300) {
        successToast({
          message: 'Success',
        });
      }
    } catch (error) {
      raiseErrorToast(error);
    }
  };

  const getAllControlsOfActions = async (action: ObjectActionDefinition) => {
    try {
      if (
        action?.properties?.workflowName &&
        props?.recordData?.name &&
        props?.recordData?.version
      ) {
        const controls = await getWorkflowControls(
          action?.properties?.workflowName || '',
          props?.recordData?.name as string,
          props?.recordData?.version as string
        );
        if (controls?.length) {
          setOpenHandler(true);
          const sortedControls = getSortedControls(controls);
          setControls(sortedControls);
          setControls(controls);
        } else {
          // directly call exceute API
          callExecuteWorfklow(action);
        }
      }
    } catch (error) {
      raiseErrorToast(error);
    }
  };

  const getAllUpdateWorkflowControls = async () => {
    try {
      const controls = await getUpdateWorkflowControls(
        props?.recordData?.name as string,
        props?.recordData?.version as string
      );
      if (controls?.length) {
        setOpenHandler(true);
        const sortedControls = getSortedControls(controls);
        setControls(sortedControls);
      }
    } catch (error) {
      raiseErrorToast(error);
    }
  };

  const callActionsHandler = (action: ObjectActionDefinition) => {
    if (action.actionType === ActionTypeEnum.UPDATE_WORKFLOW) {
      getAllUpdateWorkflowControls();
    }
    if (
      props?.actionType === ActionTypeEnum.TASK ||
      props?.actionType === ActionTypeEnum.STAGE
    ) {
      getAllTaskControls(action);
    } else {
      getAllControlsOfActions(action);
    }
    setSelectedAction(action);
  };

  const getAllTaskControls = async (action: ObjectActionDefinition) => {
    try {
      const controls = await getTaskControls(
        props?.recordData?.name as string,
        props?.recordData?.version as string
      );
      const filteredWorfklowDefinitionControls = controls?.find(
        (control) => control?.name === action?.workflowDefinitionName
      );
      let taskLevelContols: WorkflowControlsDefinition[] = [];
      if (!action?.controlName?.length) {
        if (action?.actionType === ActionTypeEnum.CHNAGE_DUE_DATE_TASK) {
          taskLevelContols = [
            {
              label: 'Task Due Date',
              ownerType: 'Custom',
              name: 'due_date__a',
              createdAtUtc: '2024-06-13T04:49:32.189Z',
              modifiedAtUtc: '2024-06-13T04:49:32.189Z',
              deletedAtUtc: null,
              isActive: true,
              description: null,
              controlType: 'VARIABLE',
              controlConfig: {
                attributeName: 'due_date__a',
                allowMultiSelect: false,
                dataType:
                  WorkflowControlVariableDataTypeEnum.DATE_ON_INITIATION,
              },
              defaultRule: 'REQUIRED',
              numberOfActiveWorkflows: 0,
              permission: 'REQUIRED',
              isDisabled: false,
              displayOrder: 1,
            },
          ];
        }
      }
      if (action?.controlName?.length) {
        taskLevelContols =
          filteredWorfklowDefinitionControls?.workflowControlsDefinition?.filter(
            (controlDefinition) => {
              return Array.isArray(action?.controlName)
                ? action?.controlName?.includes(controlDefinition?.name)
                : action?.controlName === controlDefinition?.name;
            }
          ) || [];
      }
      if (action?.objectAttributes) {
        let controls = action.objectAttributes || [];
        if (action?.actionType === ActionTypeEnum.REASSIGN_TASK) {
          controls = controls.map((control) => {
            if (
              control.controlConfig.dataType ===
              WorkflowControlVariableDataTypeEnum.USER_OBJECT_ROLES
            ) {
              return {
                ...control,
                controlConfig: {
                  ...control.controlConfig,
                  dataType:
                    WorkflowControlVariableDataTypeEnum.USER_ON_INITIATION,
                },
              };
            }
            return control;
          });
        }
        taskLevelContols = [...taskLevelContols, ...controls];
      }
      if (taskLevelContols?.length) {
        if (props?.actionType === ActionTypeEnum.TASK) {
          taskLevelContols[0].controlConfig.allowMultiSelect = false;
        } else {
          taskLevelContols[0].controlConfig.allowMultiSelect = true;
        }
      }
      setControls(taskLevelContols || []);
      setOpenHandler(true);
    } catch (error) {
      raiseErrorToast(error);
    }
  };

  const closeActionPopup = () => {
    setOpenHandler(false);
  };

  const openActionPopup = () => {
    setOpenHandler(true);
  };

  const patchPrePopulatedValue = async () => {
    try {
      const prePopulatedData = await getPrePopulatedValue(
        props?.recordData?.name as string,
        props?.recordData?.version as string
      );
      setPrePopulatedData(prePopulatedData);
    } catch (error) {
      // dont show error
    }
  };

  const contextValue = useMemo(
    () => ({
      onActionClick: refetchRecordData!,
      onTaskActionClick: callWorkflowAPIOnEvent!,
    }),
    [refetchRecordData, callWorkflowAPIOnEvent]
  );
  return (
    <WorkflowActionContext.Provider value={contextValue}>
      <WorkflowActionsView
        {...props}
        actions={actions || []}
        workflowActions={workflowActions}
        taskName={props?.taskName}
        actionType={props?.actionType}
        buttonType={props?.buttonType}
        getActionsBasedOnActionType={getActionsBasedOnActionType}
        openHandler={openHandler}
        callActionsHandler={callActionsHandler}
        closeActionPopup={closeActionPopup}
        selectedAction={selectedAction!}
        controls={controls}
        prePopulatedData={prePopulatedData}
        openActionPopup={openActionPopup}
        isActionsLoading={isActionsLoading}
        setActionsToLoad={setActionsToLoad}
        handleOpenChange={handleOpenChange}
        open={open}
      />
    </WorkflowActionContext.Provider>
  );
};
