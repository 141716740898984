import { FieldOption } from 'apps/web-client/src/screens/e-signature/e-signature.model';
import { AttributeTypeEnum } from 'libs/form-engine/src/lib/enums/attributes-enum';
import type { CSSProperties } from 'react';
import { FieldError } from 'react-hook-form';

import { ActionLevelEnum, ActionTypeEnum } from '../enums/action-type';
import { TimeOfDayPrefenceEnum } from '../enums/date-formats';
import { OperatorsEnum } from '../enums/filter-operators';
import { ObjectValidationRulesTypeEnum } from '../enums/object-validation-rules-type';
import { Validation } from './../../../form-engine/src/lib/types/validation-types';
import { FilterCriteria } from './filter-criteria-type';
import {
  JsonWithValueFromConditionsConfigSchema,
  LayoutRuleProperties,
  LayoutRulesDataSchema,
} from './layout-rules-types';
import { LookupChild } from './lookup-child-type';
import { Field } from './wizard-config';
import {
  WorkflowControlsDefinition,
  WorkflowControlVariableDataTypeEnum,
} from './workflow-types';

type ActionTypeRule = {
  actionType: 'edit' | 'update';
  showSaveButton: boolean;
  showSubmitButton: boolean;
};

export interface ObjectAttributeType {
  modifiedAtUtc: string;
  createdAtUtc: string;
  name: string;
  label: string;
  description: string;
  isAuditable: boolean;
  eSignatureRequired: boolean;
  isDomainEntity: boolean;
  tableName: string;
  title?: string;
  isDeletable: boolean;
  createRoute: string;
  objectActionDefinitions: ObjectActionDefinition[];
  objectAttributeDefinitions: ObjectAttributeDefinition[];
  isActive: boolean;
  ownerType: OwnerType;
  isVersioningEnabled: boolean;
  isLifecycleEnabled: boolean;
  labelFormat?: string;
  signatureMeaningPicklistName?: string;
  lifecycleStageGroups?: {
    [key: string]: {
      actionTypeRules?: ActionTypeRule[] | null;
      stages: string[];
      displayActively: boolean;
      displayOrder: number;
    };
  };
  objectValidationRules: ObjectValidationRule[];
}
export interface ObjectAttributeDefinitionBase {
  fixedList?: string[];
  name: string;
  label: string;
  helpText?: string;
  dataType: AttributeTypeEnum;
  key: string;
  ownerType: OwnerType;
  columnName: string;
  isInternal?: boolean;
  isAuditable?: boolean;
  isEditable?: boolean;
  displayInList?: boolean;
  isMandatory?: boolean;
  isRequired?: boolean;
  isHidden?: boolean;
  isSystemField?: boolean;
  relationshipName?: string;
  lookupFieldRelationshipName?: string;
  lookupColumnNames?: string[];
  roles?: string[];
  relationship?: Relationship;
  isCoAuthoringEnabled?: boolean;
  isVersioningEnabled?: boolean;
  maxFileSizeInBytes?: number;
  allowedExtensions?: string[] | LayoutRuleProperties[];
  allowAllExtensions?: boolean;
  isUnique?: boolean;
  picklistName?: string;
  rulesObjectName?: string;
  dataTypeKeyForFE?: AttributeTypeEnum;
  rules?: Validation[];
  childs?: LookupChild[];
  defaultReferenceFilter?: FilterCriteria[];
  propertyLookupKey?: string;
  peoplePickerDropdown?: boolean;
  excludeValuesByAttributeName?: string[];
  timeOfDayPreference?: TimeOfDayPrefenceEnum;
  doNotCopyToMinorVersion?: boolean;
  getLatestVersionOnly?: boolean;
  getAllVersions?: boolean;
  excludeSelfFromReferneceList?: boolean;
}

export interface AdditionalObjectAttributeDefinition<Value = any> {
  placeholder: string;
  defaultValue: Value;
  styles: Record<string, unknown>;
  style: CSSProperties;
  fromFilters: boolean;
  onChange: (updatedValue: Value) => void;
  valueParser: (value: Value) => unknown;
  validator: (value: Value) => boolean;
  errorParser: (error?: FieldError & Record<string, unknown>) => unknown;
  getLatestVersionOnlyForReferencePicker: boolean;
  modulesToRestrict: string[];
  maximumFilesAllowed?: number;
  allowDelete?: boolean;
}

export type ObjectAttributeDefinition<T = any> = ObjectAttributeDefinitionBase &
  Partial<AdditionalObjectAttributeDefinition<T>> &
  Partial<Field> &
  Partial<LayoutRulesDataSchema> &
  Record<string, unknown>;

export enum OwnerType {
  System = 'System',
  Custom = 'Custom',
}

export interface Relationship {
  label: string;
  ownerType: OwnerType;
  name: string;
  createdAtUtc: string;
  modifiedAtUtc: string;
  deletedAtUtc: null;
  isActive: boolean;
  relationType: string;
  objectName: string;
  deletionType: string;
  assosiatedField: string;
  objectMappingType: string;
  destinationColumnName?: string;
}

export type ObjectActionDefinition = {
  label: string;
  ownerType: string;
  name: string;
  createdAtUtc: string;
  modifiedAtUtc: string;
  deletedAtUtc: string;
  isActive: boolean;
  actionType: ActionTypeEnum;
  actionLevel: ActionLevelEnum;
  properties: PropertiesType;
  objectName: string;
  viewName: string;
  iconName: string;
  actionRule: ActionRule[] | null;
  permission?: string;
  controlType?: string;
  controlConfig?: {
    [key: string]:
      | string
      | string[]
      | WorkflowControlVariableDataTypeEnum
      | boolean;
  };
  controlName?: string[];
  objectAttributes?: WorkflowControlsDefinition[];
  workflowDefinitionName?: string;
};

export interface ActionRule {
  attribute: string;
  operator: OperatorsEnum;
  value: unknown;
}

export interface PropertiesType {
  fields?: FieldOption[];
  workflowName?: string;
}

export interface ObjectValidationRule {
  label: string;
  name: string;
  description?: string;
  isActive: boolean;
  rules: Record<string, any>;
  type: ObjectValidationRulesTypeEnum;
  errorMessage?: string;
  errorMessageLocation: string;
  validationRule: string;
  when?: JsonWithValueFromConditionsConfigSchema;
}
