import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { WorkflowControlsDefinition } from '@celito.clients/types';

export const executeAction = async (
  actionName: string,
  recordName: string,
  recordVersion: string,
  payload: object
) => {
  const response = await performRequest<{
    actions: WorkflowControlsDefinition[];
  }>(
    `/actions/execute/${actionName}?record_name=${recordName}&version=${recordVersion}`,
    ApiMethodType.POST,
    payload
  );
  return response;
};
