import { LocalizationString } from '@celito.clients/assets';
import type { Blocker } from 'history';
import { Transition } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { Location, useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { useBlocker } from '../useBlocker';

export function useCallbackPrompt(when: boolean) {
  const [isValid, setIsValid] = useState(when);
  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
  }, []);

  useEffect(() => {
    setIsValid(when);
  }, [when]);

  const confirmNavigation = useCallback(() => {
    setIsValid(false);
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    const handlePopstate = () => {
      if (isValid) {
        const isNavigating = window.confirm(LocalizationString.UNSAVED_MESSAGE);

        if (isNavigating) {
          const id = uuidv4();

          setLastLocation({
            ...window.location,
            state: id,
            key: id,
          });
          setIsValid(false);
          confirmNavigation();
        }
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [confirmNavigation, isValid]);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation: Blocker = useCallback(
    (nextLocation: Transition) => {
      if (!when) {
        return true;
      }

      if (nextLocation.action === 'REPLACE') {
        setLastLocation(nextLocation.location);
        setIsValid(false);
        setConfirmedNavigation(true);
        return false; // Block initially, but we'll navigate in the effect
      }

      setShowPrompt(true);
      setLastLocation(nextLocation.location);
      return false;
    },
    [when]
  );

  // Effect to handle navigation
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation, { replace: true });
      setConfirmedNavigation(false);

      // Set a small timeout to restore blocking after navigation
      setTimeout(() => {
        setIsValid(when);
      }, 100);
    }
  }, [confirmedNavigation, lastLocation, navigate, when]);

  // Effect to sync isValid with when
  useEffect(() => {
    if (!confirmedNavigation) {
      setIsValid(when);
    }
  }, [when, confirmedNavigation]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation, { replace: true });

      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, isValid);

  return { showPrompt, confirmNavigation, cancelNavigation };
}
