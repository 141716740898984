import {
  InHouseInputSelect,
  PeoplePicker,
  Stack,
} from '@celito.clients/shared';
import {
  createTestAttribute,
  getReferencePickerType,
} from '@celito.clients/utils';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { PeoplePickerSelctedOptions } from 'libs/shared/src/lib/people-picker/src/people-picker.model';
import { compact } from 'lodash';
import { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { userObjectName } from '../../../config';
import { transformPickerValueObject } from '../../../utils/helper';
import {
  ControlledPickerProps,
  MappingTypeEnum,
} from './controlled-picker.model';
import classes from './controlled-picker.module.css';

export const ControlledPickerView = <T extends FieldValues>(
  props: ControlledPickerProps<T>
) => {
  const [searchParams] = useSearchParams();
  const parentRecord = searchParams.get('pr') ?? undefined;

  const [filter, setFilter] = useState<any>(undefined);

  const roles = (props.attribute?.roles || []).map((role: string) => {
    return { name: role };
  });

  useEffect(() => {
    if (filter && props.setValue)
      props.setValue(props.attribute?.name as Path<T>, [] as any);
  }, [filter]);

  const watchedValue = props.watch
    ? props?.watch(props.attribute?.name as Path<T>, undefined)
    : undefined;

  const isMandatory =
    'isRequired' in props.attribute
      ? props.attribute.isRequired
      : props.attribute?.isMandatory;

  const isMulti =
    props.attribute?.relationship?.objectMappingType ===
      MappingTypeEnum.OneToMany ||
    props.attribute?.relationship?.objectMappingType ===
      MappingTypeEnum.ManyToMany;

  const dynamicFiltersToBeApplied = useMemo(() => {
    if (props.attribute.fieldFilterConditions) {
      return {
        conditions: {
          all: [
            {
              all: props.attribute.fieldFilterConditions.filters.map(
                (filterToBeApplied) => {
                  const value = filterToBeApplied.isValueDynamic
                    ? props.watch?.(filterToBeApplied.value as Path<T>)?.name
                    : filterToBeApplied.value;

                  if (value !== filter) setFilter(value);

                  return {
                    attribute: filterToBeApplied.attribute,
                    operator: filterToBeApplied.operator,
                    value,
                  };
                }
              ),
            },
          ],
        },
      };
    }
    return {}; // Ensuring a consistent return value
  }, [props.attribute]);

  const pickerType = getReferencePickerType(
    props?.attribute?.relationship?.objectName
  );

  const setParentvalue = (value: any) => {
    if (parentRecord) {
      props?.setValue?.(props.attribute.columnName as Path<T>, value);
    }
  };

  return (
    <Controller
      key={JSON.stringify(dynamicFiltersToBeApplied)}
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({ field, fieldState: { error } }) => {
        const { onChange } = field;
        if (props.attribute?.relationship?.objectName === userObjectName) {
          let excludedUsers: string[] = [];

          if (props.attribute.excludeValuesByAttributeName) {
            const dependentAttributeValue = props.watch?.(
              props.attribute.excludeValuesByAttributeName as Path<T>[]
            ) ?? { name: '', label: '' };

            excludedUsers = Array.isArray(dependentAttributeValue)
              ? compact(dependentAttributeValue)
                  .flat(Infinity)
                  .map((value: Record<string, string>) => value?.name)
              : [dependentAttributeValue.name];
          }

          return (
            <ControlledPeoplePicker
              {...props}
              isMulti={isMulti}
              isMandatory={isMandatory}
              roles={roles}
              error={error}
              excludeUsers={
                props?.excludeUsers?.length
                  ? props?.excludeUsers
                  : excludedUsers
              }
              {...field}
            />
          );
        }

        return (
          <Stack
            data-testid={`select-dropdown-${createTestAttribute(
              props.attribute?.label
            )}`}
            className={classes.section}
          >
            <InHouseInputSelect
              label={props.attribute?.label}
              required={isMandatory}
              referencePicker={{
                excludeSelfFromReferneceList:
                  props.attribute.excludeSelfFromReferneceList,
                objectName: props.attribute?.relationship?.objectName ?? '',
                getLatestVersionOnly: props.attribute?.getLatestVersionOnly,
                getAllVersions: props.attribute?.getAllVersions,
                pickerType,
                filters: dynamicFiltersToBeApplied,
                defaultReferenceFilter: props.attribute.defaultReferenceFilter,
                getSelectedOptionsInformation: !!parentRecord,
              }}
              multiselect={isMulti}
              onOptionSelect={(_, data) => {
                if (!data.optionText && !data.optionValue) {
                  onChange(undefined);
                } else if (isMulti && Array.isArray(data.selectedOptions)) {
                  const selectedOpt = data.selectedOptions.map((selected) => {
                    return {
                      ...selected.data,
                      name: selected.value,
                      label: selected.text,
                    };
                  });

                  onChange(selectedOpt);
                } else {
                  onChange({
                    ...data.data,
                    name: data.optionValue,
                    label: data.data?.label,
                  });
                }
              }}
              selectedOptions={transformPickerValueObject(
                watchedValue,
                pickerType,
                parentRecord
              )}
              options={[]}
              errorMessage={error?.message}
              disabled={!props.attribute?.isEditable || !!parentRecord}
              helperLabelText={props.attribute?.helpText}
              setParentValue={setParentvalue}
            />
          </Stack>
        );
      }}
    />
  );
};

const ControlledPeoplePicker = <T extends FieldValues>({
  isMulti,
  isMandatory,
  roles,
  error,
  excludeUsers,
  ...props
}: ControlledPickerProps<T> &
  ControllerRenderProps<T, Path<T>> & {
    isMulti: boolean;
    isMandatory?: boolean;
    roles: {
      name: string;
    }[];
    error?: FieldError;
  }) => {
  const { value, onChange } = props;
  let defaultUsers: IOption[] | IOption = [];

  if (Array.isArray(value)) {
    defaultUsers =
      value.length === 0
        ? undefined
        : value.map((v: { label: string; name: string }) => {
            return { value: v.name, text: v.label };
          });
  } else {
    const formatValue = value as { name: string; label: string };

    if (!value) defaultUsers = [];
    else if (typeof value === 'string') defaultUsers = value;
    else
      defaultUsers = {
        value: (formatValue?.name as unknown as string) ?? '',
        text: (formatValue?.label as unknown as string) ?? '',
      };
  }

  return (
    <Stack
      data-testid={`picker-container-${createTestAttribute(
        props.attribute?.label
      )}`}
      className={classes.section}
    >
      <PeoplePicker
        {...(props.attribute as Record<string, unknown>)}
        dataTestId={`people-picker-${createTestAttribute(
          props.attribute?.label
        )}`}
        label={props.attribute?.label}
        required={isMandatory || false}
        closePickerOnScrollParentRef={props.closePickerOnScrollParentRef}
        onOptionSelect={getOptionSelectHandler(isMulti, onChange)}
        roles={roles.length > 0 ? roles : undefined}
        errorMessage={error?.message || ''}
        selectedOptions={defaultUsers}
        multiselect={isMulti}
        disabled={!props.attribute?.isEditable}
        excludeUsers={excludeUsers}
        helperLabelText={props.attribute?.helpText}
        referencePickerProps={{
          getSelectedOptionsInformation: true,
          defaultReferenceFilter: props.attribute.defaultReferenceFilter,
        }}
      />
    </Stack>
  );
};

const getOptionSelectHandler = (
  isMulti: boolean,
  onChange: ControllerRenderProps['onChange']
) => {
  return (_e: BaseSyntheticEvent, data: PeoplePickerSelctedOptions) => {
    if (isMulti) {
      const multiSelected = data.selectedOptions.map((option) => {
        const adaptedOpt = option as unknown as { value: string; text: string };

        return {
          name: adaptedOpt.value,
          label: adaptedOpt.text,
        };
      });

      onChange(multiSelected);
    } else {
      if (!data.optionValue?.length && !data.optionText?.length) {
        onChange({});
        return;
      }
      onChange({
        name: data.optionValue,
        label: data.optionText!,
      });
    }
  };
};
