import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const createModalStyles = makeStyles({
  container: {
    ...shorthands.borderRadius('6px'),
    boxSizing: 'border-box',
    width: '80%',
    minWidth: '20rem',
    maxWidth: '80%',
  },

  modalHeader: {
    display: 'flex',
    ...shorthands.padding('1rem'),
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    backgroundColor: Color.drawer_header_color,
    boxShadow: 'inset 0px -1.5px 0px var(--gray-200)',
    alignItems: 'center',
    fontSize: '1.3rem',
    fontWeight: 600,
    justifyContent: 'space-between',
  },

  modalBody: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'start',
    ...shorthands.overflow('hidden'),
    paddingBottom: '0',
  },

  modalFooter: {
    ...shorthands.gap('1rem'),
    ...shorthands.padding('0.75rem'),
    marginLeft: '-1.6rem',
    justifyContent: 'flex-end',
  },
});
