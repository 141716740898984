export const LocalizationString = {
  CELITO_COPYWRIGHT_LABEL: '© 2024 Celito Tech, Inc. All Rights Reserved',
  SESSION_EXPIRED: 'Session Expired',
  SESSION_EXPIRED_MSG: 'Your session is expired.',
  SIGN_OUT: 'Sign Out Confirmation',
  CONFIRM_SIGN_OUT: 'Sign Out Confirmation',
  SIGN_OUT_MSG: 'Are you sure you want to Sign Out?',
  SIGN_OUT_SUCCESS: 'Yes, Sign Out',
  REFRESH_MSG: 'Please Refresh',
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  RECORD_ALREADY_EXISTS: 'Record already exists.',
  LOADING: 'Loading',
  LOADING_PLACEHOLDER: 'Loading...',
  ADD: 'Add',
  TITLE: 'Title',
  DISCLAIMER: 'Disclaimer for E-signature',
  IS: 'Is',
  BACK: 'Back',
  EDIT: 'Edit',

  ALL_ENABLED: 'All Enabled',
  PARTIALLY_ENABLED: 'Not all Enabled',

  LIST_VIEW: 'List View',

  TRAINER: 'Trainer',
  TRAINEE: 'Trainee',

  TAB: 'Tab',
  SUB_TAB: 'Sub Tab',

  NUMBER_NEGATIVE_ERROR: 'Number must no be negative',
  NUMBER_CANNOT_BE_ZERO: 'Number should be higher than 0',

  CELITO_NAME: 'Celito Tech, Inc',
  CANCEL_POPUP_MSG:
    'You have unsaved changes, do you want to proceed without saving the changes?',
  NO_ITEMS_TO_DISPLAY: ' No items To Display',
  NO_ITEM_RESULT_FROM_SEARCH: 'No item resulted from the search',

  SET_TAB: 'Set Tab',

  // List View Strings
  ADD_NEW: 'Add New',
  EXPORT: 'Export',
  FILTER_VIEWS: 'Views',
  EXCEL: 'EXCEL',
  CSV: 'CSV',
  PDF: 'PDF',
  DOWNLOADING_FILE: 'Downloading File...',
  THIS_MAY_TAKE_A_FEW_MOMENTS: 'This may take a few moments',
  THERE_WAS_SOME_PROBLEM_DOWNLOADING_THE_FILE:
    'There was some problem downloading the file',
  INVALID_DATE_RANGE: 'The min date cannot be higher than max date',
  CANNOT_FIND_URL_TO_TASK_COMPLETE_PAGE:
    'Cannot find redirect URL task complete page.',
  EMAIL_EXPORT_REDIRECT_MSG: 'Your Export is ready for download',
  EMAIL_EXPORT_LINK_EXPIRED_TITLE_MSG: 'Your Export link has expired',

  // List View Filters Strings
  FILTER_NAME: 'Filter Name',
  SELECT: 'Select',
  CONDITION: 'Condition',
  VALUE: 'Value',
  FILTER: 'Filter',
  FILTERS: 'Filters',
  BEGINS_WITH: 'Begins with',
  CONTAINS: 'Contains',
  ENDS_WITH: 'Ends with',
  IN: 'In',
  NOT_BEGINS_WITH: 'Not Begins with',
  NOT_CONTAINS: 'Not Contains',
  NOT_ENDS_WITH: 'Not Ends with',
  NOT_IN: 'Not In',
  RANGE: 'Range',
  GREATER_THAN: 'Greater than',
  GREATER_THAN_EQUALS: 'Greater than equals',
  LESS_THAN: 'Less than',
  LESS_THAN_EQUALS: 'Less than equals',
  EQUALS: 'Equals',
  NOT_EQUALS: 'Not equals',
  ENTER_VALUE: 'Enter value',
  ADD_FILTER: 'Add Filter',
  APPLY_FILTER: 'Apply Filter',
  RESET_ALL: 'Reset All',
  EMPTY_GRID: 'No data available',
  IS_BLANK: 'Is blank',
  IS_NOT_BLANK: 'Is not blank',
  IN_LAST_DAYS: 'In last days',
  IN_LAST_MONTHS: 'In last months',
  IN_LAST_WEEKS: 'In last weeks',
  IN_NEXT_DAYS: 'In next days',
  IN_NEXT_MONTHS: 'In next months',
  IN_NEXT_WEEKS: 'In next weeks',
  AFTER_ON_DAYS: 'After on days',
  IN_PAST: 'In past',
  SELECT_TIME: 'Select time',
  ACTIONS: 'Actions',
  REQUIRED: 'Required',
  FROM: 'From',
  TO: 'To',
  DATE: 'Date',
  TIME: 'Time',
  CANCEL: 'Cancel',
  CHECK_IN: 'Check In',
  DELETE: 'Delete',
  REMOVE: 'Remove',
  DELETE_RECORD: 'Delete Request',
  GO_BACK_TO_APP: 'Go back to app',
  THIS_ACTION_CANNOT_BE_UNDONE: 'This action cannot be undone.',
  FIELD: 'Field',
  BEFORE_VALUE: 'Before Value',
  AFTER_VALUE: 'After Value',
  FOR_ALL_OBJECTS: 'For all objects',
  PLEASE_SELECT_ATLEAST_ONE: 'Please select atleast one',
  PLEASE_SELECT_ATLEAST_TWO: 'Please select atleast two choices',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE: 'Are you sure, you want to delete?',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIELD_ON_PAGE_LAYOUT:
    'On Deletion you will lose all layout configuration for the field: ',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_DOC_INITIATION:
    'Are you sure you want to delete this document initiation?',
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE:
    'Are you sure, you want to remove this user?',
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL: 'Are you sure, you want to cancel?',
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL_FORM_DIRTY:
    'Are you sure that you want to cancel? You will lost any change made in this form!',

  DELETED_SUCCESSFULLY: 'Deleted successfully',
  SAVED_SUCCESSFULLY: 'Changes Saved successfully',
  DOCUMENT_INITIATION_DELETED: 'Document initiation deleted',
  OK: 'OK',
  PLEASE_WAIT: 'Please wait...',
  CLAIM_RECORD_MSG: 'Claiming record is in progress',
  DELETE_QUESTION: 'Delete Question',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_QUESTION:
    'Are you sure you want to delete this question',
  DELETED_QUESTION: 'Request has been deleted',
  DELETE_INITIATION: 'Delete Initiation',
  CERTIFICATE_DOWNLOADED: 'Certificate downloaded',
  CERTIFICATE_NOT_FOUND: 'Certificate not found',
  DOWNLOADING_CERTIFICATE: 'Downloading Certificate...',

  // Header strings
  DELEGATION: 'Delegation',
  DELEGATE_TO_OTHER_USERS: 'Delegate to other Users.',
  MATERIAL: 'Material',
  MATERIAl_dESCRIPTION: 'A series of educational lessons or lectures',
  REQUEST: 'Request',
  INITIATE: 'Initiate',
  REQUEST_TYPE: 'Request Type',
  INITIATION_TYPE: 'Initiation Type',

  //form-engine strings
  SAVE_REQUEST: 'Save Request',
  CREATE_REQUEST: 'Create Request',
  SAVE: 'Save',
  SAVE_DRAFT: 'Save Draft',
  SAVED_DESCRIPTION: 'Your request has been saved.',
  CREATED_DESCRIPTION: 'Your request has been created.',
  UPDATE_REQUEST: 'Edit Request',
  SUBMIT_REQUEST: 'Submit Request',
  SUBMIT: 'Submit',
  SUBMIT_DESCRIPTION: 'Your request has been submitted.',
  UPDATED_DESCRIPTION: 'Your details have been updated successfully',
  GREAT: 'Great',
  ERROR: 'Error',
  INFO: 'Info',
  WARNING: 'Warning',
  VALIDATION_DESCRIPTION: 'Please enter some data to save',
  GOT_IT: 'Ok, Got it',
  SELECT_PLACEHOLDER: 'Select...',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  YES: 'Yes',
  NO: 'No',
  UPDATE_INITIATION_CONFIRMATION_MSG:
    'Are you sure you want to proceed? By confirming, a new minor version of the {objectName} will be created.',
  UPDATE_INITIATION_CONFIRMATION: 'Update Initiation Confirmation',

  // Quiz page strings
  PREVIEW: 'Preview',
  GENERAL: 'General',
  QUESTIONS: 'Questions',
  QUESTION: 'Question',
  MANDATORY: 'Mandatory',
  RANDOMIZE: 'Randomize',
  ENTER_QUESTION_TEXT: 'Please enter Question Text',
  ENTER_FEEDBACK_TEXT: 'Please enter feedback for the user',
  ENTER_CHOOSE_QUESTION_TEXT: 'Please select Question Type',
  ENTER_TWO_CHOICES: 'Please add at least 2 choices',
  ENTER_ONE_CHOICE_CORRECT: 'Please select 1 choice as correct',
  ENTER_ATLEAST_ONE_CHOICE: 'Please select at least 2 choice as correct',
  TYPE: 'Type',
  NEXT: 'Next',
  RESTART: 'Restart',
  PREVIOUS: 'Previous',
  SETTINGS: 'Settings',
  QUESTION_TYPE: 'Question Type',
  SELECT_QUESTION_TYPE: 'Select Question type',
  LENGTH_GREATER_THAN_1024: 'Max 1024 characters',
  LENGTH_GREATER_THAN_512: 'Max 512 characters',
  LENGTH_GREATER_THAN_128: 'Max 128 characters',
  QUESTION_TEXT: 'Question Text',
  QUESTION_DETAILS: 'Question Details',
  FEEDBACK: 'Feedback',
  MANDATORY_HELPER_TEXT: 'Mark question as mandatory in this Quiz',
  RANDOMIZE_HELPER_TEXT: 'Randomize the choices when taking quiz',
  CHOICES: 'Choices',
  CORRECT: 'Correct',
  UNSAVED_QUESTION:
    ' (Unsaved question - Using section navigation wont save the question!)',
  QUIZ_UPDATED: 'Quiz has been updated, Please retake the quiz!',

  // Smart group page strings
  ADD_RULE: 'Add Rule',
  PREVIEW_RULE_RESULTS: 'Preview Rule Results',
  SET_RULE: 'Set Rule',
  RULE_RESULTS_GROUPS: 'Rule Results Groups',
  USERS: 'Users',
  USER_NAME: 'User Name',
  DEPARTMENT: 'Department',
  LOCATION: 'Location',
  LAST_MODIFIED_BY: 'Last Modified By',
  LAST_MODIFIED_DATE: 'Last Modified Date',
  SMART_GROUP: 'Smart Group',
  USER_GROUP: 'User Group',

  // Training plan strings
  UNSUBMITTED_TRAINING_PLANS: 'Unsubmitted Training Plans',
  TRAINING_PLAN_TYPE: 'Training Plan Type',
  MATERIALS: 'Materials',
  MATERIAL_NAME: 'Material Name',
  DESCRIPTION: 'Description',
  ADD_MATERIAL: 'Add Material',
  COURSES: 'Courses',
  SEQUENCE: 'Sequence',
  PLAN_AND_MATERIALS: 'Plan and Materials',
  ASSIGNMENTS: 'Assignments',
  TRAINING_PLAN_REQUEST: 'Training Plan Request',

  // Course strings
  COURSE_INITIATION: 'Course Initiation',
  ALL_UNSUBMITTED_COURSES: 'Unsubmitted Courses',
  ASSOCIATED_CURRICULA: 'Associated Curricula',

  // Curriculum strings
  CURRICULUM_INITIATION: 'Curriculum Initiation',
  ALL_UNSUBMITTED_CURRICULUM: 'Unsubmitted Curricula',

  // Training Matrix strings
  TRAINING_MATRIX: 'Training Matrix',
  REQUIRE_RE_TRAINING: 'Require re-training',
  ADD_ONE_TIME_ASSIGNMENT: 'Add one time assignment',
  COURSE: 'Course',
  CURRICULUM: 'Curriculum',
  PLEASE_SELECT_A_USER_GROUP: 'Please select a User Group or Individual User',
  TARAINING_MATERIAL_ASSIGNED_TO_FOLLOWING_USERS:
    'Training has been assigned to following users successfully',
  TRAINING_NOT_ASSIGNED_TO_ANY_USER: 'Training is not assigned to any new user',
  BELOW_USERS_ARE_NOT_ASSIGNED_TRAINING:
    'Below users are not assigned training',
  TRAINING_ASSINGMENT: 'Training Assignment',
  TRAINING_ASSINGMENT_OBJECT: 'training_assignment__a',
  INDIVIDUAL_USERS: 'Individual User(s)',

  // Taking Quiz strings
  ANSWER: 'Answer',
  RETAKE_QUIZ: 'Retake Quiz',
  REVIEW_MATERIAL: 'Review Material',
  TRAINING_SIGNOFF: 'Training Sign Off',
  DRAG: '(drag and arrange them in correct order)',
  SINGLE_SELECTION: '(choose only one from the following)',
  MULTIPLE_SELECTION: '(choose more than one)',
  BOOLEAN_CHOICE: '(select any one)',
  START_QUIZ: 'Start Quiz',
  FAILED_QUIZ: 'You have failed to pass the quiz',
  PASSED_QUIZ: 'You have successfully passed the quiz',
  CONGRATULATION: 'Congratulations!',
  SUCCESS: 'Success',
  OOPS: 'Oops !',
  SUCESS_ASSIGN: 'Training Assigned successfully',

  // User error messages
  USER_NOT_INVITED: 'User not invited',
  USER_IS_DEACTIVATED: 'User is deactivated',
  USER_DOES_NOT_HAVE_ACCESS: 'User does not have access to this version.',
  ONLY_10_USERS_ALLOWED: 'Only 10 users are allowed',
  INVALID_GRANT: 'invalid_grant',
  UNAUTHORIZED: 'Unauthorized',

  //Task
  TASK_COMPLETED_SUCCESSFULLY: 'Task completed successfully',
  TASK_INSTRUCTION: 'Task Instruction',

  // Taking training strings
  MY_ASSIGNMENTS: 'My Assignments',
  MY_TEAMS_ASSIGNMENTS: "My Team's Assignments",
  COMPANY_ASSIGNMENTS: 'Company Assignments',
  ILC_TRAINING_ASSIGNMENT: 'ILC Assignment',
  ASSIGNMENT_DATA_NOT_FOUND: 'Assignment data not found',
  TASK_DATA_NOT_FOUND: 'Task data not found',
  ASSIGNMENT_TASK_PENDING:
    'It will take a few mins for assignment to be marked complete. Please check again in a minute',
  COMPLETE: 'Complete',
  DOWNLOAD: 'Download',
  JUSTIFICATION: 'Justification',
  TRAINING_COMPLETION_DATE: 'Training Completion Date',
  UPLOAD_DOCUMENT: 'Upload Document',
  TRAINING_SIGN_OFF: 'Training Sign Off',
  TASK_TITLE: 'Task Title',
  ID: 'ID',
  NAME: 'Name',
  STATUS: 'Status',
  ASSIGNMENT_DUE_DATE: 'Assignment Due Date',
  RETRAIN_DATE: 'Retrain Date',
  COMPLETION_DATE: 'Completion Date',
  INSTRUCTION: 'Instruction',
  TRAINING_TYPE: 'Training Type',
  COMPLETION_TYPE: 'Completion Type',
  ASSIGNED: 'Assigned',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  VIEW_CERTIFICATE: 'View Certificate',
  UPLOADED_FILES: 'Uploaded files',
  ASSIGNMENT_ID: 'Assignment ID',
  COURSE_NAME: 'Course Name',
  DATE_FIELD_REQUIRED: 'Please enter a valid date',
  // Members Selectors
  SHOW_ALL_MEMBERS: 'Show All Members',
  ADD_MEMBERS: 'Add Members',
  SEARCH_KEY: 'Search Key',
  ASSIGNMENT_UPDATE_ERROR_MESSAGE:
    'An update has been made to the course while you are in the process of taking it. Please reopen the training assignment to view the reflected change',

  // Related training Material
  RELATED_COURSE: 'Related Course',

  //Document Preview
  PREVIEW_NOT_AVAILABLE: 'Preview Not Available',
  DOCUMENT_NOT_AVAILABLE: 'Document Not Available',

  //Validation Message
  REQUIRED_MSG: 'Field is required',
  MAX_1000_CHARS: 'Max 1000 characters',

  // Follow UP Page
  DECISION_DUE_DATE: 'Follow Up Decision Due Date',
  APPROVAL_DUE_DATE: 'Follow Up Approval Due Date',
  FOLLOW_UP_COMMENT: 'Follow Up Comment',
  EVALUATION_DATE: 'Evaluation Date',
  SCORM_NOT_LOADING_ERROR_MESSAGE:
    'Please contact your administrator if you are experiencing any issues loading SCORM',
  ERROR_IN_CONVERTING_BUFFER_TO_URL_MESSAGE:
    'Error in converting buffer to preview Url',

  // Dirty Modal for hookForm
  UNSAVED_MESSAGE: 'You have unsaved changes. Do you want to proceed?',
  UNSAVED_TITLE: 'Unsaved changes',

  // Controlled docs
  UNSUBMITTED_CONTROLLED_DOCUMENTS: 'Unsubmitted Documents',
  CONTROLLED_DOCUMENT_INITIATION: 'Controlled Document Initiation',
  USER_ROLE_AND_DUE_DATE: 'User Role & Due Date',
  DOCUMENT: 'Document',
  VERDICT_FROM_PREVIOUS_NODE: 'Verdict from Previous Task',
  COMMENT_FROM_PREVIOUS_NODE: 'Comment from Previous Task',
  ESIGNATURE_TEXT:
    'By signing electronically, you agree that your electronic signature is the legal equivalent of your handwritten signature and understand the meaning of the signature.',
  REJECT_REASON: 'Rejection Reason',
  COMMENTS: 'Comments',
  SUCCESSFULLY_UPDATED_MSG: 'Document Details successfully updated',
  EDIT_DOCUMENT_MSG: 'Opening the document in edit mode, please wait...',
  FORM_DETAILS_Updated: 'Fields Information successfully Updated ',
  RETIRE_DOCUMENT: 'Retire document request has been successfully submitted',
  CHECK_OUT: 'Check Out document request has been successfully submitted',
  CHECK_OUT_INITIATION: 'Check Out request has been initiated',
  CANCEL_CHECK_OUT_INITIATION: 'Cancel Check Out request has been initiated',
  CANCEL_CHECK_OUT:
    'Cancel Check Out document request has been successfully submitted',
  CANCEL_DOCUMENT: 'The request has been canceled',
  CHECK_IN_DOCUMENT:
    'Check In document request has been successfully submitted',
  UPDATE_DOCUMENT: 'Update Document',
  CLAIM_TASK: 'Task Accepted successfully',
  COLLABORATOR_TASK:
    'Collaboration is in progress; do you want to end the collaboration session?',
  CONFIRM_MESSAGE: 'Are you sure, you want to proceed ?',
  DOWNLOADING_MESSAGE: 'Downloading the document, please wait...',
  ATTACHMENTS_LIMIT_ERROR_MESSAGE: 'Maximum allowed Attachments limit reached',
  // Formengine control strings
  DROP_FILES_HERE: 'Drop the files here',
  FIL_UPLOAD_HELPER_TEXT: 'Click or drag files to this area or upload',
  ERROR_CANNOT_EDIT_NON_COLLABORATIVE_FILES:
    'The file you are trying to edit is an unsupported format for editing',
  ERROR_OCCURRED_OR_UNAUTHORIZED:
    'An error occurred or you are unauthorized to view this content',
  ERROR_OCCURRED_ON_DYNAMIC_IMPORT:
    'Failed to fetch dynamically imported module',

  // Admin Ui
  SAVE_PROPERTIES: 'Save Properties',
  UPDATE_PROPERTIES: 'Update Properties',
  DEFAULT: 'Default',
  PAGE_LAYOUT_BUILDER: 'Page Layout Builder',
  DESIGN: 'Design',
  RELATED_OBJECTS: 'Related Objects',
  LAYOUT_RULES: 'Layout Rules',
  SHOW_SECTIONS: 'Show Sections',
  ADD_SECTION: '+ Add Section',
  ADD_ROW: '+ Add Row',
  SECTION_NAME: 'Section Name',
  SAVE_DESIGN: 'Save Design',
  UPDATE_DESIGN: 'Update Design',
  CANCEL_REQUEST: 'Cancel Request',
  ADD_INPUT: '+ Add Input',
  LABEL: 'Label',
  VIEW_TYPE: 'View Type',
  REQUIRED_AT_LEST_1_ROW: 'At Least 1 row is required',
  REQUIRED_AT_LEST_1_SECTION: 'At Least 1 sections is required',
  CUSTOM_OBJECT: 'Custom Object',
  SUCCESFFULY_DELETED_PAGE_LAYOUT: 'Sucussefully deleted page layout: ',
  SUCCESFFULY_DELETED_LIST_LAYOUT: 'Sucussefully deleted list layout: ',

  LIST_LAYOUT: 'List Layout',
  LISTS: 'List Columns',
  ADD_LIST_COLUMNS: 'Add List Column',

  SUCCESFFULY_DELETED_ATTRIBUTE_FIELD: 'Sucussefully deleted the field: ',
  SUCCESFFULY_DELETED_ACTION: 'Sucussefully deleted the action: ',
  SUCCESFFULY_DELETED_OBJECT: 'Sucussefully deleted the object: ',
  PAGE_LAYOUT_EDIT: 'Editing: ',
  CREATE_OBJECT: 'Create Object',
  UPDATE_OBJECT: 'Update Object',
  KEY_INFORMATION: 'Key Information',
  SETTINGS_STAGE: 'Settings',
  OBJECT_PLURAL_LABEL: 'Object Plural Label',
  OBJECT_CLASS: 'Object Class',
  MODULE: 'Module',
  DETAIL_VIEW_NAME: 'Detail View Name',
  AT_LEAST_2_CHARACTERS: 'At least 2 characters required',
  AT_LEAST_4_CHARACTERS: 'At least 4 characters required',
  AT_LEAST_5_CHARACTERS: 'At least 5 characters required',
  HELPER_CONTENT: 'Helper Text',
  FIELD_IS_REQUIRED_DUE_LABEL: 'Fill Label field in order to generate name',
  CREATE_FIELD: 'Create Field',
  UPDATE_FIELD: 'Update Field',
  CREATE_PAGE_LAYOUT: 'Create Page Layout',
  FIELD_TYPE: 'Field Type',
  DETAILS: 'Details',
  DEFAULT_VALUE: 'Default Value',
  NEW_FIELD: 'New Field',
  WAIT_FOR_NAME_GENERATOR: 'Name is getting generated, please wait',
  SAVE_RULES: 'Save Rules',
  UPDATE_RULES: 'Update Rules',
  OPERATOR: 'Operator',
  GENERAL_SETTINGS: 'General Settings',
  IS_EMPTY_SECTION: 'Is Empty section?',
  SORTABLE_COLUMNS: 'Sortable Columns',
  FILTERABLE_COLUMNS: 'Filterable Columns',
  SORT_DEFINITION: 'Sort Definition',
  SELECT_AVAILABLE_COLUMNS: 'To select this you need select available columns',
  SELECT_DEFAULT_SORT_COLUMN: 'Select default sort column',
  NEW_RECORD_DEFINITON: 'New Record Definition',
  SAVE_CREATE_LIFECYCLE: 'Save & Create Lifecycle',
  OBJECT_LIFECYCLE: 'Object Lifecycle',
  OBJECT_LIFECYCLE_INFO_LABEL:
    'Stages and Roles permission for object lifecycle',
  LIFECYCLE_STATE_CHANGED: 'Lifecycle state changed successfully',
  STAGE_DESIGNER: 'Stage Designer',
  ADD_STAGE_GROUP: 'Add Stage Group',
  DISPLAY_ACTIVELY: 'Display Actively',
  ROLES_AND_PERMISSIONS: 'Roles & Permissions',
  ACTIVATE: 'Activate',
  UPDATE_ROLES: 'Update Roles',
  ALL_ROLES: 'All Roles',
  ALL: 'All',
  ANY: 'Any',
  AT_LEAST_1_GROUP_REQUIRED: 'At Least 1 group required',
  CUSTOM_OBJECTS: 'Custom Objects',
  SEARCH_AND_ADD: 'Search and Add',
  SEARCH_AND_ADD_ICONS: 'Search and Add Icons',
  SEARCH_RESULTS: 'Search Results',
  CREATE_ACTION: 'Create Action',
  UPDATE_ACTION: 'Update Action',
  DELETE_ALL_CONFIGURATION: 'Delete all configuration',
  FIELD_IS_EDITABLE: 'Field is Editable',
  FIELD_IS_VISIBLE: 'Field is Visible',
  FIELD_IS_REQUIRED: 'Field is Required',
  VALUE_IS_REQUIRED: 'Value is Required',
  FIELD_MIN_LENGTH: 'Please enter minimum of 5 characters',
  ARE_YOU_SURE_BACK:
    'Are you sure that you want to back, you may lost any changes done in the form!',
  ROW_DEFINITION: 'Row Definition',
  PLATFORM: 'Platform',
  NAVIGATION: 'Navigation',
  OBJECTS: 'Objects',
  PICKLIST: 'Picklist',
  ADD_STAGE: 'Add Stage',
  STAGE_PROPERTIES: 'Stage Properties',
  ENTER_STAGE_GROUP: 'Enter Stage Group',
  OWNER_TYPE: 'Owner Type',
  MORE_SETTINGS: 'More Settings',
  ENTRY_ACTIONS: 'Entry Actions',
  ENTRY_CRITERIA: 'Entry Criteria',
  USER_ACTIONS: 'User Actions',
  WORKFLOW_ACTIONS: 'Workflow Actions',
  TASK_ACTIONS: 'Task Actions',
  ATRIBUTTE_ATOMIC_SECURITY: 'Attribute Atomic Security',
  USER_ATRIBUTTE_ATOMIC_SECURITY: 'User Atomic Security',
  TASK_ACTIONS_ATOMIC_SECURITY: 'Task Atomic Security',
  WORKFLOW_ACTIONS_ATOMIC_SECURITY: 'Workflow Atomic Security',
  SAVE_STAGES_GROUPS: 'Save Stage Groups',
  SAVE_STAGE: 'Save Stage',
  ENTER_TAB_LABEL: 'Enter tab label',
  SAVE_DETAILS: 'Save Details',
  CREATE_TAB: 'Create Tab',
  CLEAR_FORM: 'Clear Form',
  VIEW_NAME: 'View Name',
  REPORT_VIEW: 'Report View Name',
  CREATE_PICKLIST: 'Create Picklist',
  UPDATE_PICKLIST: 'Update Picklist',
  CREATE_ROLES: 'Create Roles',
  CREATE_ROLE: 'Create Role',
  UPDATE_ROLE: 'Update Role',
  CREATE: 'Create',
  VALUES: 'Values',
  ITEM_LABEL: 'Item Label',
  NEW_PERMISSION_SET: 'Permission Set',
  NAME_HELPER_TEXT: 'Name is auto generated, please fill label',
  SEARCH_FIELDS: 'Search Fields',
  SET_COLUMN: 'Set Column',

  //workflow strings
  WORKFLOW_HEADER: 'New Workflow',
  ARE_YOU_SURE: 'Are you sure?',
  NODE_CHANGES_RESET_SAVE_CONFIRMATION:
    'We found nodes with changes not applied. Clicking on confirm will RESET and Save those changes.',
  EDITOR_TAB: 'Editor',
  EXECUTOR_TAB: 'Executor',
  NODE_DELETE_MSG: 'You want to delete the node?',
  TASK_LABEL_ERR_MSG: 'Please enter more than 4 characters',
  MERGE_FIELDS_ERR_MSG: 'Please select at least one merge field',
  TEMPLATE_ERR_MSG: 'Please select at least one template',
  ATTRIBUTE_ERR_MSG: 'Please select at least one attribute',
  DIRECTION_ERR_MSG: 'Please select at least one direction',
  OFFSET_ERR_MSG: 'Please enter an offset value',
  UNIT_ERR_MSG: 'Please select at least one unit',
  FIELD_NAME_ERR_MSG: 'Please select at least one field name',
  ADD_WORKFLOW_IS_AUTOMATED_HEADING: 'Automate workflow lifecycle stages',
  ADD_WORKFLOW_IS_AUTOMATED_SUBHEADING:
    'Automating the workflow lifecycle stages will use the assigned participants in the controlled document.',
  RESET: 'Reset',
  APPLY_CHANGES: 'Apply Changes',
  CONTINUE: 'Continue',
  ENTER_WORFLOW_NAME_PLACEHOLDER: 'Enter workflow name',
  WORKFLOW_NAME: 'Workflow Label',
  WORKFLOW_LABEL_VALIDATION: 'Minimum 5 and maximum 128 characters required',
  SELECT_OBJECT_TYPE: 'Select Object Type',
  SELECT_EXECUTION_TYPE: 'Select Execution Type',
  SELECT_EXECUTION_VALIDATION: 'Please select Execution Type',
  EXECUTION_TYPE: 'Execution Type',
  OBJECT: 'Object',
  SELECT_OBJECT_VALIDATION: 'Please select Object Name',
  SELECT_LIFECYCLE_EVENT: 'Select Lifecycle Event',
  LIFECYCLE_EVENT: 'Lifecycle Event',
  SELECT_LIFECYCLE_VALIDATION: 'Please select Lifecycle Event',
  WORKLOW_DESCRIPTION_PLACEHOLDER: 'Enter workflow description',
  WORKFLOW_DESCRIPTION_LABEL: 'Workflow Description',
  WORKFLOW_DESCRIPTION_VALIDATION: 'Maximum 512 characters allowed',
  ALLOW_TASK_DELEGATION: 'Allow task delegation',
  ALLOW_MULTISELECT: 'Allow multi-select',
  TASK_REQUIREMENT: 'Task Requirement',
  TASK_REQUIREMENT_OPTION_REQUIRED: 'Required',
  TASK_REQUIREMENT_OPTION_OPTIONAL: 'Optional',
  ASSIGNMENT_TASK_ASSIGN_TO_ALL_USERS:
    'Assign to all users in particular group',
  ASSIGNMENT_TASK_MAKE_AVAILABLE_TO_USERS:
    'Make available to all users in particular group',
  COMPLETE_TASK_WITHOUT_VIEWING: 'Complete the task without viewing the item',
  PARTICIPANTS: 'Participants',
  DATES: 'Dates',
  FIELD_PROMPTS: 'Field Prompts',
  VARIABLES: 'Variables',
  FIELD_PROMPTS_HEADER: 'Field Prompts Controls',
  VARIABLES_HEADER: 'Variable Controls',
  INSTRUCTIONS_HEADER: 'Initiation Instruction Control',
  START_RULES_HEADER: 'Startup Control Rules',
  START_TRIGGER_HEADER: 'Startup Trigger Rules',
  UPDATE_CONFIG_HEADER: 'Workflow Update Configuration',
  UPDATE_CONFIG_CHECKBOX_TITLE:
    'The Workflow Owner can update workflow parameters.',
  PARTICIPANTS_RADIO_VALUE_ONE:
    'Allow workflow initiator to select participants',
  PARTICIPANTS_RADIO_VALUE_TWO: 'Use user roles as participants',
  PARTICIPANTS_RADIO_VALUE_THREE: 'Use fields as participants',
  DATE_RADIO_VALUE_ONE: 'Allow workflow initiator to select due dates',
  DATE_RADIO_VALUE_TWO: 'Select custom date',
  LABEL_REQUIRED: 'Label is required',
  LABEL_MAX_LENGTH: 'Label must be at most 32 characters',
  LABEL_MUST_BE_UNIQUE: 'Label must be unique',
  OWNER_CAN_UPDATE_TASK_THROUGH_ACTIONS:
    'Owner can update task through actions',
  COMMENT_IS_REQUIRED: 'Comment is required',
  ALLOW_WORKFLOW_OWNER_TO_RECEIVE_TASK: 'Allow workflow owner to receive task',
  SUBMIT_REDIRECT_URL: 'Submit Redirect Url',
  DEFAULT_NODE_MSGE: 'This node cannot be added/updated by user from UI',

  //Related Objects
  FILL_OPTIOS: '(Fill below options to include this section into design)',
  SECTION_ADDED: '(Section included)',

  // Layout Rules
  SET_LAYOUT_RULES: 'Set Layout Rules',
  REMOVE_LAYOUT_RULES: 'Remove Layout Rules',
  LAYOUT_FIELD_IS_REQUIRED: 'Field is Required',
  LAYOUT_FIELD_IS_VISIBLE: 'Field is Visible',
  LAYOUT_FIELD_IS_EDITABLE: 'Field is Editable',

  //Users
  DEPARTMENT_INACTIVE:
    'Department value is no longer active, please select an active Department',

  //common
  NO_RESULTS: 'No Results Found',
  SHOW_ALL_RESULTS: 'Show All Results',
  UNABLE_TO_UPLOAD_FILE: 'Unable to load the file',

  FIELD_SELECTOR:
    'Select a object and enable the usage of the merge field selector (Click in a menu option to copy it to clipboard)',
  // Workflow History
  TASK_STATUS: 'Task Status',
  DUE_DATE: 'Task Due Date',
  ASSIGMENT_DATE: 'Assignment Date',
  COMPLETED_DATE: 'Task Completed Date',
  TASK_OUTCOME: 'Task Outcome',
  VERSION: 'Version',
  COMMENT: 'Comments',
  NO_TASK_FOUND: 'No tasks found',
  WORKFLOW_EXECUTION_TEXT: 'You are about to start the {dynamicText}',
  NO_ACTION_FOUND: 'No Action Found',
  UPDATE: 'Update',
  REASSIGN: 'Reassign',
  UPDATE_TEXT: 'Yes, Update Workflow',
  UPDATE_WORKFLOW_CONFIRMATION_TEXT: 'Update Workflow Confirmation',
  ADD_PARTICIPANTS: 'Add Participants',
  CHANGE_WORKFLOW_DUE_DATE: 'Change Due Date',
  REASSIGN_USER: 'Reassign User',
  UPDATE__CONFIRMATION_DESCRIPTION:
    'Updating the workflow will not affect the completed tasks. Are you sure you want to continue?',
  ADD_PARTCIPANTS_CONFIRMATION:
    'Are you sure you want to add the participant to the workflow?',
  REASSIGN_USER_CONFIRMATION:
    'Are you sure you want to reassign the task? Once confirmed, the task will be reassigned to the new tasker',
  CHNAGE_DUE_DATE_CONFIRMATION:
    'Are you sure you want to change the task due date? Once confirmed, the task due date will be updated with the new selected due date.',
  UPDATE_INSTRUCTION:
    'Updates will only affect future tasks. However, updating a user with an active task may impact their ability to complete it.',

  // In-app notifications
  MARK_ALL_NOTIFICATIONS_AS_READ_CONFIRMATION:
    'Are you sure you want to mark all notifications as Read?',
  REMOVE_NOTIFICATION_CONFIRMATION:
    'Are you sure you want to remove this notification?',
  REMOVE_ALL_NOTIFICATIONS_CONFIRMATION:
    'Are you sure you want to remove all notifications?',

  // Restoring
  RESTORING_PLACEHOLDER: 'Restoring...',
  RESTORED_SUCCESSFULLY: 'Restored successfully',

  //Object Validation
  SUBMISION_FAILED: 'Submission Failed',
  SUBMISION_ERROR: 'The next sections contains errors that need to be fixed:',
  NEW_VALIDATION: 'New Validation',
  CREATE_VALIDATION: 'Create Validation',
  UPDATE_VALIDATION: 'Update Validation',
  RULE_DEFINITION: 'Rule Definition',
  DEFAULT_REFERENCE_FILTER: 'Default reference filter',

  //Export Import
  EXPORT_IMPORT: 'Export & Import',
  REVIEW_DESCRIPTION: 'Review your selection before exporting',
  SELECT_EXPORT_DESCRIPTION: 'Select the data to export',
  EXPORT_DESCRIPTION: 'Export your data',
  EXPORT_FORMAT_LABEL: 'Select the format to export',
  EXPORT_COMPLETE: 'Your export is complete!',
  EXPORT_DOWNLOAD_MESSAGE: `If your download didn't start, `,
  EXPORT_DOWNLOAD_LINK: 'click here',
  SELECT_ERR_TITLE: 'No data selected',
  EXPORT_ERR_MESSAGE: 'Please select data to export',
  LOADING_FILE_DATA: 'Loading data from file...',
  IMPORT: 'Import',
  LOADING_FILE_DESCRIPTION: 'This may take a while',
  UPLOAD_FILE_DESCRIPTION: 'Upload your metadata file',
  SELECT_IMPORT_DESCRIPTION: 'Select the data to import',
  IMPORT_DESCRIPTION: 'Import your data',
  DEPENDENCY_ERROR_TITLE: 'Missing dependencies',
  ERROR_TITLE: 'The following errors were found',
  IMPORT_ERR_MESSAGE: 'Please select data to import',
  IMPORT_OPERATION_LABEL: 'Select the import operation',
  IMPORT_REVIEW_DESCRIPTION: 'Review your selection before importing',
  ACTION_PROPERTIES: 'Action Properties',
  SCHEDULE_SETTINGS: 'Schedule settings',
};
