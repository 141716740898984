import { LocalizationString } from '@celito.clients/assets';
import {
  CustomDialogView,
  InHouseInputSelect,
  TextField,
} from '@celito.clients/shared';
import { Divider } from '@fluentui/react-components';
import { returnSelectedOptionFromOptions } from 'libs/core/src/utils/getSelectedOptionFromOptions';
import { Controller, useFormContext } from 'react-hook-form';

import {
  CreateWorkflowModalViewProps,
  CreateWorkflowProps,
  ExecutionTypeEnum,
  executionTypeOptions,
  lifeCycleEventOptions,
} from './create-workflow-modal.model';
import { WorkflowSettings } from './scheduled-settings/scheduled-settings.component';
import { workflowSettingsStyles } from './scheduled-settings/scheduled-settings.styles';

const CreateWorkflowForm = (props: CreateWorkflowProps) => {
  const { watch, control } = useFormContext();
  const styles = workflowSettingsStyles();

  const executionType = watch('executionType');

  return (
    <>
      <div className={styles.settingsContainer} data-testId={props.dataTestId}>
        <div className={styles.row}>
          <Controller
            control={control}
            name="label"
            rules={{ required: true, minLength: 5, maxLength: 128 }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                value={value}
                placeholder={LocalizationString.ENTER_WORFLOW_NAME_PLACEHOLDER}
                label={LocalizationString.WORKFLOW_NAME}
                labelText={LocalizationString.WORKFLOW_NAME}
                required={true}
                errorMessage={
                  error?.message
                    ? LocalizationString.WORKFLOW_LABEL_VALIDATION
                    : undefined
                }
                minLength={5}
                maxLength={128}
              />
            )}
          />
          <Controller
            control={control}
            name="objectName"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InHouseInputSelect
                selectedOptions={returnSelectedOptionFromOptions(
                  props.objectNameOptions,
                  value
                )}
                required
                placeholder={LocalizationString.SELECT_OBJECT_TYPE}
                label={LocalizationString.OBJECT}
                options={props.objectNameOptions}
                onOptionSelect={(event, option) => {
                  onChange(option.optionValue);
                }}
                errorMessage={
                  error?.message
                    ? LocalizationString.SELECT_OBJECT_VALIDATION
                    : undefined
                }
              />
            )}
          />
          <Controller
            control={control}
            name="description"
            rules={{ required: false, maxLength: 512 }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <TextField
                labelText="Description"
                placeholder={LocalizationString.WORKLOW_DESCRIPTION_PLACEHOLDER}
                label={LocalizationString.WORKFLOW_DESCRIPTION_LABEL}
                required={false}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                errorMessage={
                  error?.message
                    ? LocalizationString.WORKFLOW_DESCRIPTION_VALIDATION
                    : undefined
                }
                size="large"
                maxLength={512}
              />
            )}
          />
        </div>
        <div className={styles.row}>
          <Controller
            control={control}
            name="executionType"
            rules={{ required: true }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <InHouseInputSelect
                selectedOptions={returnSelectedOptionFromOptions(
                  executionTypeOptions,
                  value
                )}
                required
                placeholder={LocalizationString.SELECT_EXECUTION_TYPE}
                label={LocalizationString.EXECUTION_TYPE}
                options={executionTypeOptions ?? []}
                onOptionSelect={(event, option) => {
                  onChange(option.optionValue);
                }}
                errorMessage={
                  error?.message
                    ? LocalizationString.SELECT_EXECUTION_VALIDATION
                    : undefined
                }
              />
            )}
          />
          {executionType === ExecutionTypeEnum.OBJECT_EVENT && (
            <Controller
              control={control}
              name="lifeCycleEvent"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <InHouseInputSelect
                  selectedOptions={returnSelectedOptionFromOptions(
                    lifeCycleEventOptions,
                    value
                  )}
                  required
                  placeholder={LocalizationString.SELECT_LIFECYCLE_EVENT}
                  label={LocalizationString.LIFECYCLE_EVENT}
                  errorMessage={
                    error?.message
                      ? LocalizationString.SELECT_LIFECYCLE_VALIDATION
                      : undefined
                  }
                  options={lifeCycleEventOptions ?? []}
                  onOptionSelect={(event, option) => {
                    onChange(option.optionValue);
                  }}
                />
              )}
            />
          )}
        </div>
      </div>
      {executionType === ExecutionTypeEnum.SCHEDULED && (
        <div>
          <Divider>{LocalizationString.SCHEDULE_SETTINGS}</Divider>
          <WorkflowSettings />
        </div>
      )}
    </>
  );
};

export const CreateWorklowModalView = (props: CreateWorkflowModalViewProps) => {
  const { reset, handleSubmit } = useFormContext();
  return (
    <CustomDialogView
      headerTitle={LocalizationString.WORKFLOW_HEADER}
      children={
        <CreateWorkflowForm objectNameOptions={props.objectNameOptions} />
      }
      showFooter
      cancelButtonTitle={LocalizationString.CANCEL}
      confirmButtonTitle={LocalizationString.CONTINUE}
      open={props.openWorkFlowModal}
      cancel={() => {
        reset();
        props.handleWorkflowModalOpen();
      }}
      isLoading={props?.isLoading}
      confirm={handleSubmit(props.onCreateWorkflowSubmit)}
    />
  );
};
